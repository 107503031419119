<template>
  <div class="app-header-wrapper">
    <div class="app-header">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center flex-grow-1">
          <VBtn
            fab
            width="46"
            height="46"
            :disabled="isBackBtnDisabled"
            data-test="prev-btn"
            @click="move"
          >
            <VIcon
              class="tt-black--text"
              size="20"
            >
              $arrowLeftLight
            </VIcon>
          </VBtn>
          <div
            class="mx-4 align-center flex-grow-1"
            :class="{ 'd-flex' : $vuetify.breakpoint.smAndUp }"
          >
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              class="logo-container align-self-center mr-4"
              tile
            >
              <VImg
                v-if="logoImg"
                :src="logoImg"
                class="logo"
                @error="onLogoImgError"
              />
              <div
                v-else
                class="logo-stub"
              />
            </div>
            <div class="flex-grow-1 mr-2">
              <div class="text-h6 clamp">
                {{ surveyName }}
              </div>

              <div
                v-if="isEndScreen"
                class="tt-black--text text--lighten-2 body-2"
              >
                {{ $t('finishing') }}
              </div>
            </div>
            <div
              v-if="!anonymity"
              class="tt-orange--text text--lighten-1 d-flex align-start body-2"
            >
              <VIcon
                color="tt-orange"
                size="13"
                class="ma-1"
              >
                $exclamationCircle
              </VIcon>
              {{ $t('anonymityTitle') }}
            </div>
          </div>
        </div>
        <LanguageSwitcherMini
          v-if="isLocaleSwitcherShowed"
          :languages="languages"
          :class="{ 'margin-on-desktop' : $vuetify.breakpoint.lgAndUp }"
        />
      </div>
    </div>
    <VProgressLinear
      :value="progress"
      background-color="tt-white"
      height="2"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LanguageSwitcherMini from '@/views/LanguageSwitcherMini.vue';
import potokLogo from '../../assets/img/potok-logo.svg';

const { VUE_APP_GOGHA_API_ADDRESS: baseUrl = 'http://localhost:3000/' } = process.env;

export default {
  name: 'AppHeader',
  components: { LanguageSwitcherMini },
  inject: ['Names'],

  props: {
    languages: {
      type: Array,
      default: () => [],
    },
    surveyStatePage: {
      type: Number,
      default: 1,
    },
    isBackBtnDisabled: {
      type: Boolean,
      default: false,
    },
    anonymity: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      logoImg: '',
    };
  },

  computed: {
    ...mapState(['progressBar']),
    surveyName() {
      return this.progressBar.surveyName;
    },
    progress() {
      return this.progressBar.progress;
    },
    isEndScreen() {
      return this.$route.name === this.Names.R_USER_SURVEY_FINISHED;
    },
    isFirstScreen() {
      return this.surveyStatePage === 1;
    },
    isSurveyPage() {
      return this.$route.name === this.Names.R_USER_SURVEY_PAGE;
    },
    isLocaleSwitcherShowed() {
      return this.languages.length > 1;
    },
  },

  watch: {
    surveyName: {
      handler(value) {
        let title = this.$t('pageTitleSuffix')?.toString();
        if (value) {
          title = `${value} - ${title}`;
        }
        document.title = title;
      },
      immediate: true,
    },
    '$store.state.companyId': {
      handler(value) {
        this.logoImg = value && `${baseUrl}company/${value}/logo`;
      },
      immediate: true,
    },
  },

  methods: {
    move() {
      return this.isSurveyPage && !this.isFirstScreen ? this.$emit('nav-back') : this.$router.go(-1);
    },
    onLogoImgError() {
      this.logoImg = potokLogo;
    },
  },
};
</script>

<style scoped lang="scss">
.logo, .logo-stub {
  overflow: hidden;
  max-height: 44px;
  min-width: 120px;
  max-width: 120px;
}

.logo-container {
  width: auto !important;
}

.app-header-wrapper {
  position: fixed;
  z-index: 5;
  width: 100%;
  background: white;
}

.app-header {
  box-shadow: inset 0px -0.5px 0px map-get($tt-black, 'lighten-4') !important;
  padding: 20px;

  .v-btn {
    background: #ffffff !important;
    box-shadow: 0px 4px 48px rgba(11, 18, 24, 0.16) !important;
  }

  .clamp {
    width: 100%;
    padding: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  .margin-on-desktop {
    margin-right: 130px;
  }
}
</style>
